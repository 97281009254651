import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import YoutubeVideoFTP from 'components/Software/Other_Platforms/Synology/VideoFTPSynology';
import YoutubeVideoCam from 'components/Software/Other_Platforms/Synology/VideoCamSynology';
import YoutubeVideoWQHDCam from 'components/Software/Other_Platforms/Synology/VideoCamWQHDSynology';
import NavButtons from 'components/Software/Other_Platforms/NavButtons';
import ForumBox from 'components/Software/Other_Platforms/Synology/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Software for your INSTAR IP Camera",
  "path": "/Software/Other_Platforms/Synology/",
  "dateChanged": "2017-12-05",
  "author": "Mike Polinowski",
  "excerpt": "All INSTAR IP cameras are supported by the Synology Surveillance Station. Manage all your cameras and video recordings.",
  "image": "./P_SearchThumb_Synology.png",
  "social": "/images/Search/P_SearchThumb_Synology.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Other_Synology_white.webp",
  "chapter": "Software"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const EuiSpacer = makeShortcode("EuiSpacer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Camera Surveillance Software' dateChanged='2017-12-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='All INSTAR IP cameras are supported by the Synology Surveillance Station. Manage all your cameras and video recordings.' image='/images/Search/P_SearchThumb_Synology.png' twitter='/images/Search/P_SearchThumb_Synology.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Other_Platforms/Synology/' locationFR='/fr/Software/Other_Platforms/Synology/' crumbLabel="Synology" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "other",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#other",
        "aria-label": "other permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Other`}</h2>
    <h3 {...{
      "id": "synology-surveillance-station",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#synology-surveillance-station",
        "aria-label": "synology surveillance station permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Synology Surveillance Station`}</h3>
    <p>{`In order to integrate your INSTAR IP camera in `}<a href="https://www.synology.com/en-uk/surveillance/" target="_blank" rel="noopener noreferrer">{`Synology DSM`}</a>{` we recommend you to download the `}<a href="http://www.synology.com/en-global/dsm/" target="_blank" rel="noopener noreferrer">{`Latest DSM Version from here`}</a>{` or check the system update in the Synology web user interface if your operating system is up-to-date. The Surveillance Station is available from the App Center in the Synology web user interface.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <YoutubeVideoWQHDCam mdxType="YoutubeVideoWQHDCam" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "add-a-hd-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-hd-camera",
        "aria-label": "add a hd camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a HD Camera`}</h3>
    <p>{`After you have upgraded your Synology to the newest DSM Version please access the web interface of your Synology station and start the Surveillance Station as shown below. `}<a href="http://techtest.org/die-synology-surveillance-station-eine-kamera-empfehlung/" target="_blank" rel="noopener noreferrer">{`German Guide`}</a>{`.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <YoutubeVideoCam mdxType="YoutubeVideoCam" />
    <YoutubeVideoFTP mdxType="YoutubeVideoFTP" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      